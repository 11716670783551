<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow" :header="false">
      <template v-slot:header></template>
      <template v-slot:actions>
        <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn></template
      >
      <a-table-f-api
        ref="table"
        :api="url"
        :model="model"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
      />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, keyDetect, doubleClickDetect, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, keyDetect, doubleClickDetect, genModel],
  components: {
    ViewItem: () => import("./views/objectsView"),
  },
  data() {
    return {
      idEdit: 0,
      idItemShow: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.objects,
      url: "/mechti/objects/completed",
      url1: "/mechti/objects",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: { status: 90 },
        paramName: "objectsCompleted",
      },
    };
  },
  created() {
    this.$root.title = "Завершенные объекты";
  },
  computed: {
    model() {
      let res = this.getModelList(this.m, "list", false);
      return res;
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    onDblClick(d) {
      if (this.clickRow === d.row.id || this.$root.typeClick == 1) {
        this.idItemShow = d.row.id;
        return;
        this.$router.push({ name: "objects_view", params: { id: d.row.id } });
      }
      this.clickRow = 0;
    },
    onDetectClick(d) {
      if (this.counter == 1)
        this.click = setTimeout(() => {
          this.onSingleClick(d);
          this.click = undefined;
          this.counter = 0;
        }, 250);
      if (this.counter == 2) {
        this.counter = 0;
        clearTimeout(this.click);
        this.click = undefined;
        this.onDblClick(d);
      }
      this.clickRow = d.row.id;
    },
  },
};
</script>
